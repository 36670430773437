import { app } from '@/config';
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import Vue from "vue";


export const BASE_URL = app.base_url;
export const BASE_URL2 = app.api_url;

const API_TIMEOUT = app.api_timeout || 10000

const versions = {
  "3": app.api_url,
  "3.1": app.api_url_v31 || `${app.api_url}/v3.1`
}


export const httpClient = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 90000, // should be reduced by real API response time to improve user experience, it shouldn't take more than 10 seconds
  headers: {
    Authorization: Vue.$cookies
      ? `Bearer ${Vue.$cookies.get("token", "")}`
      : "",
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
httpClient.interceptors.request.use(
  function (config) {
    if (Vue.$cookies) {
      config.headers["Authorization"] = `Bearer ${Vue.$cookies.get(
        "token",
        ""
      )}`;
    }
    return config;
  },
  function (error) {
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);

const Request = (version) => {

  // const baseURL = versions[version || "3"]
  const baseURL = 'https://api.matadortracker.com/v' + [version || "3"]
  const token = Vue.$cookies ? Vue.$cookies.get("token", "") : ''

  const client = axios.create({
    baseURL,
    timeout: API_TIMEOUT,
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });


  client.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      Bugsnag.notify(error);
      return Promise.reject(error);
    }
  )


  client.interceptors.response.use(
    function (response) {

      if (response.status === 401) {
        localStorage.removeItem("user_id");
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax'
        document.cookie = 'role=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax'
        document.location.href = '/'
      }

      return response;
    },
    function (error) {
      Bugsnag.notify(error);
      return Promise.reject(error);
    }
  )

  return client
}

export const httpClient2 = axios.create({
  baseURL: `${BASE_URL2}`,
  timeout: 60000,
  headers: {
    Authorization: Vue.$cookies
      ? `Bearer ${Vue.$cookies.get("token", "")}`
      : "",
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
httpClient2.interceptors.request.use(
  function (config) {
    if (Vue.$cookies) {
      config.headers["Authorization"] = `Bearer ${Vue.$cookies.get(
        "token",
        ""
      )}`;
    }
    return config;
  },
  function (error) {
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);
httpClient2.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);

// EXPRESS API
export const getAlertCounter = () => httpClient2.get("v3/notification/totalNotif", {
  params: { status: "unread" }
});
export const postAddDriver = (params) => httpClient2.post("v3/driver/add_driver", params);
export const postEditDriver = (params) => httpClient2.post("v3/driver/edit_driver", params);
export const postRemoveDriver = (params) => httpClient2.post("v3/driver/delete_driver", params);
export const getDriver2 = (params) => httpClient2.get("v3/driver", { params });
export const getDeviceUnit = () => httpClient2.get("v3/device/unit");
export const getReportData = (params) => httpClient2.get("v3/report", { params });
export const getLogReportData = (params) => httpClient2.get("v3/logreport", { params });
export const getReportXlsx = (params) => httpClient2.get("v3/report/print", { params });
export const postAddTracking = (params) => httpClient2.post("v3/tracking/add", params);
export const postEditTracking = (params) => httpClient2.post("v3/tracking/edit", params);
export const postRemoveTracking = (params) => httpClient2.post("v3/tracking/delete", params);
export const postTrackingCheckedChange = (params) => httpClient2.post("v3/tracking/check", params);
export const getTracking = (params) => httpClient2.get("v3/tracking", { params });
export const getTrackingMap = (params) => httpClient2.get("v3/tracking/location", { params });
export const postAddUserPrivilage = (params) => httpClient2.post("v3/userprivilage/add", params);
export const postEditUserPrivilage = (params) => httpClient2.post("v3/userprivilage/edit", params);
export const postRemoveUserPrivilage = (params) => httpClient2.post("v3/userprivilage/delete", params);
export const getUserPrivilage = (params) => httpClient2.get("v3/userprivilage", { params });
export const getGeofence = (params) => httpClient2.get("v3/geofence", { params });
export const postAddGeofence = (params) => httpClient2.post("v3/geofence/add", params);
export const postUpdateGeofence = (params) => httpClient2.post("v3/geofence/edit", params);
export const postDeleteGeofence = (params) => httpClient2.post("v3/geofence/delete", params);
export const getMonitoring = (params) => httpClient2.get("v3/monitoring", {
  params
});
export const getVehicleListExpress = (params) => httpClient2.get("v3/vehicle", {
  params
});
export const getVehicleDetail = (params) => httpClient2.get("v3/vehicle/detail", { params });
export const getVehicleSpeed = (params) => httpClient2.get("v3/vehicle/vehicle_speed", { params });
export const getFuelConsumtion = (params) => httpClient2.get("v3/vehicle/fuel_consumption", { params });
export const getBrandVehicleList = (params) => httpClient2.get("v3/vehicle/brands", { params });
export const postAddSeries = (params) => httpClient2.post("v3/vehicle/add_series", params);
export const getNotificationList = (params) => httpClient2.get("v3/notification", { params });
export const getReportedList = (params) => httpClient2.get("v3/vehicle/report_list", { params });
export const getNotificationSettings = (params) => httpClient2.get("v3/notification/setting", { params });
export const getTrip = (params) => httpClient2.get("v3/vehicle/trip", { params });
export const postResetMonitoring = body => httpClient2.post("v3/monitoring/reset", body);
export const getPopupNotification = user_id => httpClient2.get("v3/notification/popup_notification", { params: { user_id } });
export const postSetDeviceStatus = body => httpClient2.post("v3/vehicle/set_status", body);
export const postToggleAlertNotification = body => httpClient2.post("v3/notification/alert_notification", body);
export const getVehicleType = () => httpClient2.get("v3/vehicle/type");
export const getSeriesByVehicleType = vehicle_type_id => httpClient2.get("v3/vehicle/series", { params: { vehicle_type_id } });
export const getVehicleByUserId = (user_id) => httpClient2.get("v3/vehicle", { params: { user_id } });
export const getVehicleByOrganizationId = (organization_id) => httpClient2.get("v3/vehicle", { params: { organization_id } });
export const getVehicle = (params) => httpClient2.get("v3/vehicle/list", { params });
export const getVehicleList = (params) => httpClient2.get("v3/vehicle", { params });
export const postAddVehicle = (params) => httpClient2.post("v3/vehicle/add_vehicle", params);
export const postEditVehicle = (params) => httpClient2.post("v3/vehicle/edit_vehicle", params);
export const postAddVehicleType = ({ name, wiper_status }) => httpClient2.post("v3/vehicle/add_type", { name, wiper_status });
export const postDeleteVehicle = unit_id => httpClient2.post("v3/vehicle/delete_vehicle", { unit_id });
export const getDeviceAll = (params) => httpClient2.get("v3/device/all", { params });
export const getDeviceEmpty = (params) => httpClient2.get("v3/device/empty_unit", { params });
export const postChangePasswordUser = data => httpClient2.post("v3/setting/password", data);
export const postChangeLogo = (image) => httpClient2.post("v3/setting/logo", { image });
export const postRemoveLogo = file => httpClient2.post("v3/setting/logo", file = "/public/car.png");

export const getDeviceDetail = (params) => httpClient2.get("v3/device/detail", { params });
export const getLogo = (params) => httpClient2.get("v3/setting/logo", { params });
export const getRole = (params) => httpClient2.get("v3/setting/role", { params });
export const postRoleUpdate = data => httpClient2.post("v3/setting/role/update", data);
export const getPosition = (params) => httpClient2.get("v3/setting/position", { params });
export const postPosition = data => httpClient2.post("v3/users/position/add", data);
export const postApiKey = data => httpClient2.post("v3/setting/key", data);
export const getApiKey = user_id => httpClient2.get("v3/setting/key", { params: { user_id } });
export const postGeocoding = data => httpClient2.post("v3/setting/geocoding", data);
export const getGeocoding = status => httpClient2.get("v3/setting/geocoding", { params: { status } });
export const postUnreadNotifications = (user_id) => httpClient2.post("v3/notification/unreadNotif", { user_id });
export const getUserDetail = user_id => httpClient2.get("v3/user", { params: { user_id } });

// LARAVEL API
export const postLogin = ({ email, password }) => httpClient2.post("v3/auth/login", { email, password });
export const getCurrentUser = () => httpClient2.get("v3/user/detail");
/* export const getCurrentUser = () => httpClient2.get("v3/user",{ params }); */
export const postDeleteAlertSetting = body => httpClient2.post("v3/notification/remove_notification", body);
export const postRemoveAlertSetting = body => httpClient2.post("v3/notification/remove_setting", body);
export const getUsersList = (params) => httpClient2.get("v3/users", { params });
export const getAllUsers = () => httpClient2.get("v3/users/all");
export const getNewToken = (user_id) => httpClient2.get("v3/user/get_new_token", { params: { user_id } });
export const getCheckFeedback = (params) => httpClient2.get("v3/users/check_feedback", { params });
export const postFeedback = body => httpClient2.post("v3/users/send_feedback", body);
export const getOrganizationList = (params) => httpClient2.get("v3/users/organization");
export const getPositionList = (params) => httpClient2.get("v3/users/position", { params });
export const getJobList = (params) => httpClient2.get("v3/setting?setting_name=job_title", { params });
export const getDevice = (params) => httpClient.get("v1/device", { params });
export const getDriver = (params) => httpClient.get("v1/driver", { params });
export const postAvatar = (params) => httpClient2.post("v3/users/change_profile_picture", params);
export const postAddUser = (params) => httpClient2.post("v3/users/add", params);
export const postEditUser = (params) => httpClient2.post("v3/users/edit", params);
export const postRemoveUser = (params) => httpClient2.post("v3/users/delete", { user_id: params.user_id });
export const postAddDevice = data => httpClient2.post("v3/device/add", data);
export const postEditDevice = (params) => httpClient2.post("v3/device/edit", params);
export const postRemoveDevice = (params) => httpClient2.post("v3/device/delete_device", params);
export const getNetworkOperatorList = (params) => httpClient2.get("v3/device/network_operator", { params });
export const getSetting = (params) => httpClient2.get("v3/setting", { params });
// export const postAlertSetting = data =>
// httpClient.post("v1/notification/update_setting", data);
export const postAlertSetting = data => httpClient2.post("v3/notification/edit_setting", data);
export const postAddAlertSetting = data => httpClient2.post("v3/notification/add_setting", data);

//rental
export const getEtalase = (params) => httpClient2.get("v3/etalase", {
  params
});
export const getManageEtalase = (params) => httpClient2.get("v3/etalase/manage_etalase",
  params
);
export const getEtalaseDetail = (params) => httpClient2.get("v3/etalase/detail", { params });
export const getVehicleVINBySeries = (params) => httpClient2.get("v3/vehicle", { params });
export const postAddEtalase = data => httpClient2.post("v3/etalase/add", data);
export const postDeleteEtalase = etalase_id => httpClient2.post("v3/etalase/delete", { etalase_id });
export const postShowHideEtalase = (params) => httpClient2.post("v3/etalase/set_status_etalase", params);
export const postSetAutoAssignEtalase = (params) => httpClient2.post("v3/etalase/set_auto_assign", params);
export const postEditEtalase = (params) => httpClient2.post("v3/etalase/edit", params);
export const postCancelOrder = (params) => httpClient2.post("v3/orders/cancel", params);
export const getRentList = (params) => httpClient2.get("v3/orders/rent", { params });

export const getRentalDashboard = (params) => httpClient2.get("v3/report/dashboard", { params });
export const getRentalSetting = (params) => httpClient2.get("v3/setting/rental", { params });

//cart
export const postAddCart = (params) => httpClient2.post("v3/orders/add_cart", params);
export const postEditCart = (params) => httpClient2.post("v3/orders/edit_cart", params);
export const postDeleteCart = (params) => httpClient2.post("v3/orders/delete_cart", params);
export const getListCart = (params) => httpClient2.get("v3/orders/cart", { params });
export const getDetailInvoice = (params) => httpClient2.get("v3/orders/invoice_detail", { params });

//payment
export const postOrderVehicle = data => httpClient2.post("v3/etalase/order", data);
export const postOfflinePayment = data => httpClient2.post("v3/payment/submitPayment", data);
export const getDetailOrder = (params) => httpClient2.get("/v3/orders/detail", { params });
//setting rental
export const postRentalSetting = data => httpClient2.post("v3/setting/update_rental_setting", data);
//invoice
export const postSendInvoice = (params) => httpClient2.post("v3/orders/send_invoice", params);
//signin
export const postRegister = (params) => httpClient2.post("v3/auth/register", params);
export const postSocialMedia = (params) => httpClient2.post("v3/auth/social_media", params);
export const postEmailVerification = (params) => httpClient2.post("v3/auth/email_verification", params);
export const postProfile = (params) => httpClient2.post("v3/auth/setup_profile", params);
export const postResendEmail = (params) => httpClient2.post("v3/auth/resend_email_verification", params);



//orderlist
export const getOrderList = (params) => httpClient2.get("v3/orders", { params });
export const postConfirmOrder = (params) => httpClient2.post("v3/orders/confirm", params);
export const postPickup = (params) => httpClient2.post("v3/orders/pickup", params);
export const postReturn = (params) => httpClient2.post("v3/orders/return", params);

//payment
export const postOffline = (params) => httpClient2.post("v3/payment/submitPayment", params);
export const postMidtrans = (params) => httpClient2.post("v3/payment/midtrans", params);

// subdomain
export const postSubdomain = (params) => httpClient2.post("v3/subdomain/create", params)
export const checkSubdomain = (params) => httpClient2.get("v3/subdomain/check", { params })

//superboard
export const getSuperboard = (params) => httpClient2.get("v3/report/superboard", { params })

//chart dashboard
export const getOperatingHour = (params) => httpClient2.get("v3/vehicle/graph", { params })
export const getOperatingMile = (params) => httpClient2.get("v3/vehicle/graph", { params })
export const getIdleTime = (params) => httpClient2.get("v3/vehicle/graph", { params })
export const getTemperature = (params) => httpClient2.get("v3/vehicle/graph", { params })
export const getFuels = (params) => httpClient2.get("v3/vehicle/graph", { params })




// migrate module new (developer olds) (sementara menggunakan api yang sudah tersedia untuk pengecekan dan karena minta cepat dedline)
const ver_31 = "3.1"

const GeneratorApi = {
  list: (params) => Request(ver_31).get(`/generators`, { params }),
  detail_device: (params) => Request(ver_31).get(`/generators/devices/${params.uuid}`),
  get_devices: (params) => Request(ver_31).get("/generators/devices", { params }),
  device_names: (params) => Request(ver_31).get("/generators/device_names", { params }),
  create: (params) => Request(ver_31).post(`/generators`, params),
  update: (params) => Request(ver_31).patch(`/generators/${params.genset_id}`, params),
  delete: (params) => Request(ver_31).delete(`/generators/${params.genset_id}`),
  remove_geofence: (params) => Request(ver_31).delete(`/generators/${params.genset_id}/geofence`),
  get_statistic: (params) => Request(ver_31).get("/generators/statistics", { params }),
  update_opt_time: (id, params) => Request(ver_31).patch(`/generators/${id}/operating_time`, params)
}

const TeltonikaApi = {
  list: (params) => Request(ver_31).get(`/teltonika`, { params }),
  detail_device: (params) => Request(ver_31).get(`/teltonika/devices/${params.uuid}`),
  device_names: (params) => Request(ver_31).get("/teltonika/device/device_names", { params }),
  create: (params) => Request(ver_31).post(`/teltonika`, params),
  delete: (params) => Request(ver_31).delete(`/teltonika/${params.teltonika_id}`),
}


export const getGeneratorDevices = (params) => Request(ver_31).get("/generators/devices", { params });
export const patchUpdateDeviceStatus = (params) => Request(ver_31).patch(`/generators/devices/${params.uuid}`, { action: params.action })
export const postGenerator = (params) => Request(ver_31).post(`/generators`, params)
export const getDetailGenerator = (params) => Request(ver_31).get(`/generators/${params.genset_id}`)
export const deleteGeneratorGeo = (params) => Request(ver_31).delete(`/generators/${params.genset_id}`)
export const postCreateGeo = (params) => Request(ver_31).post(`/generators/geofence`, params)
export const postUpdateGeo = (params) => Request(ver_31).patch(`/generators/${params.genset_id}`, params)
export const getReport = (params) => Request(ver_31).get(`/generators/report`, { params })
export const toggleStatusGeofence = (params) => Request(ver_31).patch(`/generators/${params.genset_id}/geofence_status`)
export const getDriver31 = (params) => Request(ver_31).get("/driver", { params });
export const patchUpdateDriverGeofence = (params) => Request(ver_31).patch(`/driver/${params.driver_id}`, params)


const VehicleApi = {
  monitoring_list: (params) => Request(ver_31).get(`/vehicles/monitoring`, { params }),
  monitoring_obd: (params) => Request(ver_31).get(`/vehicles/monitoring/${params.imei}/obd`),

  list: (params) => Request(ver_31).get(`/vehicles`, { params }),
  detail: (id) => Request(ver_31).get(`/vehicles/${id}`),
  restart: (params) => Request(ver_31).patch(`/vehicles/${params.vehicle_id}/device/${params.imei}/restart`, { action: params.action }),
  switch_status: (params) => Request(ver_31).patch(`/vehicles/${params.vehicle_id}/device/${params.imei}/switch_status`, { action: params.action }),
  get_latest_condition: (params) => Request(ver_31).get(`/vehicles/${params.vehicle_id}/device/${params.imei}/latest_condition`),
  collect_series: (params) => Request(ver_31).get(`/vehicles/series`, { params }),

  satellite_list: (params) => Request(ver_31).get(`/vehicles/satellites`, { params }),
  satellite_detail: (params) => Request(ver_31).get(`/vehicles/satellites/${params.vehicle_id}`),
  satellite_get_history: (vehicle_id, params) => Request(ver_31).get(`/vehicles/satellites/${vehicle_id}/log_histories`, { params }),
  satellite_get_history_by_date: (vehicle_id, params) => Request(ver_31).get(`/vehicles/satellites/${vehicle_id}/log_histories_by_date`, { params }),
  satellite_change_state: (vehicle_id, params) => Request(ver_31).patch(`/vehicles/satellites/${vehicle_id}/change_status`, params),
  satellite_get_statistic: (vehicle_id, params) => Request(ver_31).get(`/vehicles/satellites/${vehicle_id}/statistic`, { params }),
  satellite_download_report: (vehicle_id, params) => Request(ver_31).get(`/vehicles/satellites/${vehicle_id}/download_report`, { params }),
  satellite_latest_condition: (vehicle_id, params) => Request(ver_31).get(`/vehicles/satellites/${vehicle_id}/latest_condition`, { params }),


  satellite_download_report1: (vehicle_id, params) => httpClient2.get(`/v3/satellites/${vehicle_id}/download`, { params }),

  // @deprected
  detail_vehicle: (params) => httpClient2.get("v3/vehicle/detail", { params }),
  list_by_org: (params) => httpClient2.get("v3/vehicle", { params: { organization_id: params.organization_id } }),
  delete: (params) => httpClient2.post("v3/vehicle/delete_vehicle", { unit_id: params.unit_id })
}

const DriverApi = {
  user_list: (params) => Request(ver_31).get(`/driver/users`, { params }),
  list: (params) => Request(ver_31).get("/driver", { params }),
  detail: (params) => Request(ver_31).get(`/driver/${params.user_id}`, params),
  create: (params) => Request(ver_31).post('/driver', params),
  update: (params) => Request(ver_31).patch(`/driver/${params.user_id}`, params),
  delete: (params) => Request(ver_31).delete(`/driver/${params.driver_id}`),

  history_list: (params) => Request(ver_31).get(`/driver/histories`, { params }),
  history_detail: (params) => Request(ver_31).get(`/driver/histories/${params.history_id}/${params.driver_id}`),

  emergency_list: (params) => Request(ver_31).get(`/driver/emergencies`, { params }),
  change_emergency_status: (params) => Request(ver_31).patch(`/driver/${params.driver_id}/sos_signal/${params.sos_id}`, params),
  collect_statistic: (params) => Request(ver_31).get(`/dashboard/statistic`, { params }),
  list_out_of_geofence: (params) => Request(ver_31).get(`/driver/out_of_geofence`, { params }),
  geofence_out_of_edge: (params) => Request(ver_31).get(`/driver/out_of_geofence`, { params }),
}

const DeviceApi = {
  list: (params) => Request(ver_31).get('/devices', { params }),
  report: (id, params) => Request(ver_31).get(`/devices/${id}/report`, { params }),
  create: (params) => Request(ver_31).post(`/devices`, params),
  update: (params) => Request(ver_31).patch(`/devices/${params.device_id}`, params),
  remove: (params) => Request(ver_31).delete(`/devices/${params.device_id}`),
  strict_protection: (params) => Request(ver_31).patch(`/devices/${params.device_id}/strict_protection`),
  latest_condition: (params) => Request(ver_31).get(`/devices/${params.uuid}/latest_condition`),
  location_tracking: (params) => Request(ver_31).get(`/devices/${params.uuid}/location_tracking`, { params }),
  network_operator: (params) => Request(ver_31).get('/devices/network_operator'),
  create_network: (params) => Request(ver_31).post(`/devices/network_operator`, params),
  collect_device_types: (params) => Request(ver_31).get(`/devices/types`),
  create_device_type: (params) => Request(ver_31).post(`/devices/types`, params)
}

const DeviceUnitApi = {
  list: (params) => Request(ver_31).get('/units/devices', { params })
}

const UserApi = {
  list: (params) => Request(ver_31).get('/users', { params }),
  create: (params) => Request(ver_31).post('/users', params),
  remove: (params) => Request(ver_31).delete(`/users/${params.user_id}`),
  update: (params) => Request(ver_31).patch(`/users/${params.user_id}`, params),
  create_role: (params) => Request(ver_31).post('/users/positions', params),
  create_org: (params) => Request(ver_31).post('/users/organizations', params),

  // this should be searchable
  position_list: params => Request(ver_31).get('/users/positions', { params }),
  organization_list: params => Request(ver_31).get('/users/organizations', { params }),
}

const TrackApi = {

  list: (params) => Request(ver_31).get(`/tracks`, { params }),
  create: (params) => Request(ver_31).post(`/tracks`, params),
  update: (params) => Request(ver_31).patch(`/tracks/${params.tracking_unit_id}`, params),
  toggle_check: (params) => Request(ver_31).patch(`/tracks/toggle_view`, params),
  location: (params) => Request(ver_31).get(`/tracks/${params.tracking_unit_id}/location`, { params: { user_id: params.user_id } }),
  device_list: (params) => Request(ver_31).get(`/tracks/unit`, { params }),

  // @deprecated
  remove: (params) => httpClient2.post("v3/tracking/delete", params),

}

const Dashboard = {
  collect_statistic: (params) => Request(ver_31).get(`/dashboard/statistic`, { params }),
  collect_dashboard_stat: () => Request(ver_31).get(`/dashboard/graph_statistic`),
  collect_graph_chart: (key, params) => Request(ver_31).get(`/dashboard/graph_statistic/${key}`, { params }),
  collect_alerts: (params) => Request(ver_31).get(`/dashboard/kpi_alert`, { params }),
  collect_kpi_params: () => Request(ver_31).get(`/dashboard/kpi_params`),
  collect_statuses: () => Request(ver_31).get(`/dashboard/kpi_status_vehicle`),

  update_stat_cfg: (id, params) => Request(ver_31).put(`/dashboard/graph_statistic/${id}`, params),
  search_vehicle: params => Request(ver_31).get(`/dashboard/kpi_vehicles`, { params }),
  list_kpi: params => Request(ver_31).get(`/dashboard/kpis`, { params }),
  update_kpi: params => Request(ver_31).put(`/dashboard/kpis`, params),
  delete_kpi: id => Request(ver_31).delete(`/dashboard/kpis/${id}`),
  latest_kpi: () => Request(ver_31).get(`/dashboard/kpi_latest`),
  kpi_detail: (id) => Request(ver_31).get(`/dashboard/kpis/${id}`),
  latest_obd: (params) => Request(ver_31).get(`/dashboard/kpi_dtc`, { params }),

  get_threshold: () => Request(ver_31).get(`/dashboard/settings/operate-threshold`),
  update_threshold: (threshold) => Request(ver_31).put(`/dashboard/settings/operate-threshold`, { threshold }),
  update_alert_setting: (params) => Request(ver_31).put(`/dashboard/kpi_alert`, params),
  collect_notifications: (params) => Request(ver_31).get(`/dashboard/notifications`, { params })
}

export default {
  generator: { ...GeneratorApi },
  teltonika: { ...TeltonikaApi },
  vehicle: { ...VehicleApi },
  driver: { ...DriverApi },
  device: { ...DeviceApi },
  device_unit: { ...DeviceUnitApi },
  user: { ...UserApi },
  tracking: { ...TrackApi },
  dashboard: { ...Dashboard }
}
