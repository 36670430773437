import axios from 'axios';
import config from '../config';


export const minifyChartAmount = (value, _index, _ticks) => {
  if (value >= 1000000000) return ((value / 1000000000).toFixed(1) + ' B').replace(".0", '');
  if (value >= 1000000) return ((value / 1000000).toFixed(1) + ' M').replace(".0", '');
  if (value >= 1000) return ((value / 1000).toFixed(1) + ' K').replace(".0", '');
  return value.toFixed(1).toString().replace(".0", '');
}


export const delay = (timeout) => new Promise(resolve => {
  timeout = timeout || 5000
  setTimeout(() => resolve(true), timeout)
})



export const features = {
  TELTO: 'teltonika'
}


export const isTeltoDevice = (self, device) => {

  const isFeatureEnabled = () => {
    let data = localStorage.getItem('features') || {}
    try {
      data = JSON.parse(data)
      data = data.list
    } catch (e) { }

    if (typeof data[features.TELTO] == undefined) return true

    return data[features.TELTO] ? true : false
  }

  if (!isFeatureEnabled()) return false
  if (config.app.telto_device_type_id !== device.device_type_id) return false

  return true
}

export const isFeatureAllowed = (feature) => {
  let data = localStorage.getItem('features') || {}

  try {
    data = JSON.parse(data)
    data = data.list
  } catch (e) { }

  if (typeof data[feature] == undefined) return true

  return data[feature] ? true : false
}


export const meterToKm = (payload = 0, expectNumber = false) => {
  const value = (payload / 1000).toFixed(2);
  return expectNumber ? value : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' km';
}

export const secondToHour = (payload = 0, expectNumber = false) => {
  const value = (payload / 3600).toFixed(2);
  return expectNumber ? parseFloat(value) : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + (Math.floor(value) >= 2 ? 'hrs' : 'hr');
}



export const TimeFormat = {
  secToHour: (total) => {
    let def = {
      hour: 0,
      minute: 0,
      h: 0,
      m: 0
    }

    if (!total) return def

    let toMin = 0
    let toHour = 0
    let remain = 0

    if (total > 3600) {
      toMin = Math.floor(total / 60)
      toHour = Math.floor(toMin / 60)
      remain = (toHour * 60) - toMin
    } else {
      toHour = 0
      remain = Math.floor(total / 60)
    }

    return {
      hour: toHour,
      minute: remain,
      h: toHour,
      m: Math.abs(remain)
    }
  }
}

export const DownloadFile = (fileUrl) => {
  let locked = false

  const fetching = (url, done) => {
    let filename = url.split('/').pop()
    if (locked) return
    locked = true

    axios({
      method: 'GET',
      url: fileUrl,
      responseType: 'blob'
    })
      .then(({ data, status }) => {
        console.log({ data, status })

        if (status === 200) {
          let file = window.URL.createObjectURL(new Blob(data))
          let elm = document.createElement('elm')
          elm.style.display = 'none'
          elm.href = file
          elm.setAttribute('download', filename)
          document.body.appendChild(elm)
          elm.click()

          done()
        }
      })
      .catch(e => { })
      .finally(() => locked = false)
  }


  let startTime = Date.now()
  let expired = startTime + (10 * 1000) // 10 seconds trying to download

  let download = setInterval(() => {
    console.log(Date.now())

    if (Date.now() > expired)
      clearInterval(download)

    try {
      fetching(fileUrl, () => clearInterval(download))
    } catch (e) { console.log(e) }
  }, 1000)
}


export const numberFormat = new Intl.NumberFormat('id-ID')


export const each = (obj, callback) => {
  return Object.keys(obj).forEach(callback)
}
