import Config from '@/config';

export const LATITUDE_ASEAN = Config.google.map.lat
export const LONGITUDE_ASEAN = Config.google.map.lng
export const ZOOM_ASEAN = Config.google.map.zoom


export function GetCenterPolygon(path) {
    const bounds = new google.maps.LatLngBounds();
    path.forEach(coor => {
        bounds.extend(new google.maps.LatLng(coor.lat, coor.lng))
    })
    return {
        bounds,
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng()
    }
}


export function InitPolygon(line, mapRef, zoom, position, disableCenter) {
    line = line || []
    position = position || {}
    if (!mapRef) return null

    let loadMap = Date.now()
    const initMap = setInterval(() => {

        if ((Date.now() - loadMap) > 23000)
            clearInterval(initMap)

        if (!google) return false
        clearInterval(initMap)

        let { bounds, lat, lng } = GetCenterPolygon(line.path)

        if (position.lat && position.lng) {
            lat = position.lat
            lng = position.lng
        }

        if (!lat || !lng) {
            if (Config.google.map) {
                lat = Config.google.map.lat
                lng = Config.google.map.lng
                zoom = Config.google.map.zoom
            } else {

                // temporary default
                lat = 11.55558811377022
                lng = 104.93367411863993
                zoom = 12

            }
        }

        mapRef.$_getMap().then(map => {
            if (!!line.length) {
                if (zoom)
                    return map.setZoom(zoom)

                map.fitBounds(bounds)
                map.setZoom(map.zoom + .5);
                return
            }

            if (!disableCenter)
                map.setCenter({ lat, lng })

            map.setZoom(map.zoom + .5);
        })

    }, 500)
}


export function InitMarker(positions, mapRef, callback, icon) {
    positions = positions || []
    if (!mapRef) return

    mapRef.$_getMap().then(map => {

        if (!!positions.length) {
            positions.forEach(pos => {
                const options = {
                    position: {
                        lat: parseFloat(pos.lat) || 0,
                        lng: parseFloat(pos.lng) || 0
                    },
                    map
                }
                if (icon) options.icon = icon
                new window.google.maps.Marker(options);
            })
        }

        if (typeof callback === "function") {
            let params = { map }
            callback(params)
        }
    })
}
