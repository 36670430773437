import { app } from '@/config';
import io from 'socket.io-client';
import Vue from "vue";
import Vuex from "vuex";
import Bugsnag from '../plugins/bugsnag';
import { getListCart } from "./api.js";

import { CanAccess } from './rbac';

import EV from './emitter';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    auth: {},
    sidebar: [],
    features: {},
    theme: localStorage.getItem('theme-color'),
    showAvatarModal: false,
    showFeedbackModal: false,
    showLogoutModal: false,
    showLoadingTrack: false,
    showVerificationModal: false,
    showRegisterModal: false,
    showBackToEtalaseModal: false,
    loginOrSignUp: false,
    afterLoginPath: "/dashboard",
    alertCounter: 0,
    devMode: window.location.hostname === "localhost",
    statusCart: false,
    redirectCheckout: [],
    statusLogin: false,
    totalSOS: 0,
    totalOutOfGeofence: 0,
    // devMode: false
  },
  mutations: {
    log(state, props) {
      let mod = props.mod || ''
      let e = props.e || ''

      Bugsnag.notify(`${mod} -> ${e.stack}`)
    },
    listen_socket(state, payload) {
      if (!state.socket)
        throw new Error

      const callback = payload ? payload.callback : null
      if (typeof callback === 'function')
        callback(state.socket)

      state.socket.off('message')
      state.socket.on('message', payload => {

        EV.emit('global_message', payload)
        EV.emit('socket', payload)

        switch (payload.event) {
          case 'universal':
            state.totalSOS = payload.data.sos
            state.totalOutOfGeofence = payload.data.out_edge
            break;

          case 'new_emergency':
            state.totalSOS = state.totalSOS + 1
            break
        }

      })

    },

    incTotalSOS(state) {
      state = { ...state, totalSOS: state.totalSOS + 1 }
    },
    setSidebar(state, payload) {
      state.sidebar = payload
    },
    setAuth(state, payload) {
      state.auth = payload;
    },
    setTheme(state, payload) {
      state.theme = payload;
    },
    setSocket(state, payload) {
      state.socket = payload.socket
    },
    verifyFeature(state, payload) {
      let currentFeature = localStorage.getItem('features') || {}
      try {
        currentFeature = JSON.parse(currentFeature)
      } catch (e) {
        currentFeature = {}
      }

      let features = payload.features || {}
      if (!currentFeature.updated_at && features.list)
        return localStorage.setItem("features", JSON.stringify(features))

      if (features.updated_at && currentFeature.updated_at !== features.updated_at)
        return localStorage.setItem("features", JSON.stringify(features))

    },
    setShowVerificationModal(state, payload) {
      state.showVerificationModal = payload;
    },
    setShowBackToEtalaseModal(state, payload) {
      state.showBackToEtalaseModal = payload;
    },
    setShowAvatarModal(state, payload) {
      state.showAvatarModal = payload;
    },
    setShowFeedbackModal(state, payload) {
      state.showFeedbackModal = payload;
    },
    setShowLogoutModal(state, payload) {
      state.showLogoutModal = payload;
    },
    setShowRegisterModal(state, payload) {
      state.showRegisterModal = payload;
    },
    setLoginOrSignUp(state, payload) {
      state.loginOrSignUp = payload;
    },
    setAfterLoginPath(state, payload) {
      state.afterLoginPath = payload;
    },
    setAlertCounter(state, payload) {
      state.alertCounter = payload;
    },
    setCartStatus(state, payload) {
      state.statusCart = payload;
    },
    setRedirectCheckout(state, payload) {
      state.redirectCheckout = payload
    },
    setHaveToLoginStatus(state, payload) {
      state.statusLogin = payload;
    }
  },
  getters: {
    canAccess(state) {
      return (role, key, access) => CanAccess(role, key, access)
    },

    initCallback(state) {

      return (interval, timeout, callback) => {
        timeout = timeout || 0
        timeout = timeout * 1000

        let endTry = Date.now() + timeout

        const init = setInterval(() => {
          if (Date.now() >= endTry)
            return clearInterval(init)

          try {
            callback(() => clearInterval(init))
          } catch (e) { console.log(e) }

        }, interval)
      }

    },
    gettersAuth(state) {
      return state.auth;
    },
    gettersStatusLogin(state) {
      return state.statusLogin;
    },
    gettersCartStatus(state) {
      return state.statusCart;
    },
    gettersRedirectCheckout(state) {
      if (Object.keys(state.redirectCheckout).length > 0) {
        console.log(state.redirectCheckout, "ini checkout redirect")
        return state.redirectCheckout;
      } else {
        var value = "Tidak Ada";
        console.log(value, "ini checkout redirect")
        return value;
      }
    },
    allowFeature(feature) {
      let currentFeature = localStorage.getItem('features') || {}
      try {
        currentFeature = JSON.parse(currentFeature)
      } catch (e) {
        currentFeature = {}
      }

      let features = payload.features || {}
      if (!currentFeature.updated_at && features.list)
        return localStorage.setItem("features", JSON.stringify(features))

      if (features.updated_at && currentFeature.updated_at !== features.updated_at)
        return localStorage.setItem("features", JSON.stringify(features))
    },


    permissions(state) {
      return (module, action) => {
        // console.log({module, action})
        return true
      }
    },
  },
  actions: {
    initSocket(ctx, payload) {

      if (!app.socket_enabled) return
      let socket
      setTimeout(() => {
        socket = io(app.socket_url, {
          path: app.socket_path,
          extraHeaders: {
            auth: payload.token
          }
        })

        ctx.commit('setSocket', { socket })
      }, 5000)
    },
    setAfterLoginPathAction(context, request) {
      context.commit('setAfterLoginPath', request);
    },
    async getUserCart(context, request) {
      try {

        let response = await getListCart({ user_id: request });
        if (response.data.result.length <= 0) {
          context.commit('setCartStatus', false)
        } else {
          context.commit('setCartStatus', true)
        }
      } catch (error) {

      }
    },
    async redirectUserCheckout(context, request) {
      context.commit('setRedirectCheckout', request);
      console.log(request, "ini req ya")
    },
    async haveToLogin(context, request) {
      context.commit('setHaveToLoginStatus', request)
    },
    initCallback(ctx, payload) {
      let { interval, timeout, callback } = payload

      let endTry = Date.now() + (Number(timeout) || 0) * 1000
      let error

      const init = setInterval(() => {
        if (Date.now() >= endTry) {
          ctx.commit('log', { mod: 'init_callback', e: error })
          return clearInterval(init)
        }

        try {
          callback(() => clearInterval(init))
        } catch (e) { error = e }

      }, interval)

    },
  }
});

// REGISTERED MODULES

export default store;
